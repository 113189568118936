import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import store from "@/store";
import seedgreenAPI from "@/lib/seedgreen-api";

const abortSignals: { [key: string | number]: AbortController } = {};
const abortIrrigationBlockSignals: { [key: string | number]: AbortController } = {};

function stopActivityIfCompleted(iTask: any) {
	if (!iTask.completed) return;
	iTask.activity
		.filter((time: { end: null }) => time.end == null)
		.forEach((time: { end: Date }) => {
			time.end = new Date();
		});
}

const api = {
	loadFromPlanting(plantingId: number) {
		console.debug("api.irrigation.loadFromPlanting", plantingId);
		if (!plantingId) {
			console.debug("plantingId is null, skipping api.irrigation.loadFromPlanting");
			return Promise.resolve();
		}

		// Cancel any pending loads of the same planting if they already exist
		if (abortSignals[plantingId]) {
			abortSignals[plantingId].abort();
		}
		abortSignals[plantingId] = new AbortController();

		const payload = {
			value: plantingId,
		};
		OfflineQueue.add("irrigation.loadFromPlanting", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (queueItem.endpoint === "irrigation.loadFromPlanting" && queueItem.payload.value === payload.value)
				return 1;
		});
		return Promise.resolve();
	},
	_loadFromPlanting(payload: { value: string | number }) {
		console.debug("api.irrigation._loadFromPlanting", payload.value);
		return seedgreenAPI
			.get("Irrigation", {
				params: {
					plantingId: payload.value,
				},
				signal: abortSignals[payload.value]?.signal,
			})
			.then((response) => {
				// Build hashes for virtual tasks
				response.data
					.filter((irrigation: { id: any }) => !irrigation.id)
					.forEach(
						(irrigation: { hash: string; location: { plantingIds: any[] }; scheduledDate: any }) =>
							(irrigation.hash = `${irrigation.location?.plantingIds?.[0]}-${irrigation.scheduledDate}`),
					);

				response.data.map((irrigation: { id: any; hash: any }) =>
					store.dispatch("updateIrrigation", {
						irrigationId: irrigation.id || irrigation.hash,
						value: irrigation,
						valueType: "irrigation",
					}),
				);
			});
	},

	loadFromIrrigationBlock(id: string | number) {
		console.debug("api.irrigation.loadFromIrrigationBlock", id);
		if (!id) {
			console.debug("id is null, skipping api.irrigation.loadFromIrrigationBlock");
			return Promise.resolve();
		}

		// Cancel any pending loads of the same planting if they already exist
		if (abortIrrigationBlockSignals[id]) {
			abortIrrigationBlockSignals[id].abort();
		}
		abortIrrigationBlockSignals[id] = new AbortController();

		const payload = {
			value: id,
		};
		OfflineQueue.add("irrigation.loadFromIrrigationBlock", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "irrigation.loadFromIrrigationBlock" &&
				queueItem.payload.value === payload.value
			)
				return 1;
		});
		return Promise.resolve();
	},
	_loadFromIrrigationBlock(payload: { value: string | number }) {
		console.debug("api.irrigation._loadFromIrrigationBlock", payload.value);
		return seedgreenAPI
			.get("Irrigation", {
				params: {
					irrigationBlockId: payload.value,
				},
				signal: abortIrrigationBlockSignals[payload.value]?.signal,
			})
			.then((response) => {
				// Build hashes for virtual tasks
				response.data
					.filter((irrigation: { id: any }) => !irrigation.id)
					.forEach(
						(t: {
							hash: string;
							location: { irrigationBlockId: any; plantingIds: any[] };
							scheduledDate: any;
						}) =>
							(t.hash = `${t.location?.irrigationBlockId}-${t.location?.plantingIds?.[0]}-${t.scheduledDate}`),
					);

				response.data.map((irrigation: { id: any; hash: any }) =>
					store.dispatch("updateIrrigation", {
						irrigationId: irrigation.id || irrigation.hash,
						value: irrigation,
						valueType: "irrigation",
					}),
				);
			});
	},
	create: function (value: { activity: any; tempId?: any; completed?: any }) {
		console.debug("api.irrigation.create", JSON.stringify(value));
		value.activity = value.activity || [];
		value.tempId = window.URL.createObjectURL(new Blob([])).split("/").pop();
		stopActivityIfCompleted(value);
		const payload = {
			value,
			valueType: "irrigation",
		};

		OfflineQueue.add("irrigation.create", payload);

		// Update local state
		return store.dispatch("createIrrigation", payload);
	},
	_create: function (payload: { value: { tempId: any } }) {
		console.debug("api.irrigation._create", JSON.stringify(payload));
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("Irrigation", payload.value)
						.then((response) => response.data)
						.then((updated) => {
							updated.tempId = payload.value.tempId;
							store
								.dispatch("commitIrrigation", updated)
								.then(() => {
									// rather than do both, update the irrigation block and only update the planting if we have one
									// because some tasks were migrated from planting-based blocks to irrigation blocks
									if (updated.location?.irrigationBlockId) {
										return api.loadFromIrrigationBlock(updated.location.irrigationBlockId);
									}
									if (updated.location?.plantingIds?.[0]) {
										return api.loadFromPlanting(updated.location?.plantingIds?.[0]);
									}
								})
								.then(() => resolve(updated));
						})
						.catch(reject);
				})
				.catch(reject);
		});
	},
	update: function (value: any) {
		console.debug("api.irrigation.update", JSON.stringify(value));
		stopActivityIfCompleted(value);
		const payload = {
			irrigationId: value.id || value.hash,
			value,
			valueType: "irrigation",
		};

		// Enqueue API-related mutations
		OfflineQueue.add("irrigation.update", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (queueItem.endpoint === "irrigation.update" && queueItem.payload.irrigationId === payload.irrigationId)
				return 1;
		});

		// Update local state
		return store.dispatch("updateIrrigation", payload);
	},
	_update: function (payload: { value: any }) {
		console.debug("api.irrigation._update", JSON.stringify(payload));
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("Irrigation", payload.value)
						.then((response) => response.data)
						.then((data) => {
							console.debug("api.irrigation._update.done", JSON.stringify(data));
							payload.value.id = data.id;
							store
								.dispatch("updateIrrigation", payload)
								.then(() => {
									// rather than do both, update the irrigation block and only update the planting if we have one
									// because some tasks were migrated from planting-based blocks to irrigation blocks
									if (data.location?.irrigationBlockId) {
										return api.loadFromIrrigationBlock(data.location.irrigationBlockId);
									}
									if (data.location?.plantingIds?.[0]) {
										return api.loadFromPlanting(data.location?.plantingIds?.[0]);
									}
								})
								.then(() => resolve(payload.value));
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	delete: function (value: { id: any }) {
		const payload = {
			irrigationId: value.id,
			value,
			valueType: "irrigation",
		};

		// Enqueue API-related mutations
		OfflineQueue.add("irrigation.delete", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (queueItem.endpoint === "irrigation.delete" && queueItem.payload.irrigationId === payload.irrigationId)
				return 1;
		});

		// Update local state
		return store.dispatch("deleteIrrigation", payload);
	},
	_delete: function (payload: { value: { location: { irrigationBlockId: any; plantingIds: number[] } } }) {
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.delete("Irrigation", { data: payload.value })
						.then((response) => response.data)
						.then((data) => {
							resolve(data);
							if (payload.value.location?.irrigationBlockId)
								return api.loadFromIrrigationBlock(payload.value.location?.irrigationBlockId);
							if (payload.value.location?.plantingIds?.[0])
								return api.loadFromPlanting(payload.value.location?.plantingIds?.[0]);
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	createActivity: function (value: { tempId: string | undefined }) {
		value.tempId = window.URL.createObjectURL(new Blob([])).split("/").pop();
		const payload = {
			value,
			valueType: "irrigationActivity",
		};

		OfflineQueue.add("irrigation.createActivity", payload);

		// Update local state
		return store.dispatch("createIrrigationActivity", payload);
	},
	_createActivity: function (payload: { value: { tempId: any } }) {
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("IrrigationActivity", payload.value)
						.then((response) => response.data)
						.then((updated) => {
							updated.tempId = payload.value.tempId;
							store.dispatch("commitIrrigationActivity", updated).then(() => {
								resolve(updated);
							});
						})
						.catch(reject);
				})
				.catch(reject);
		});
	},
	updateActivity: function (value: { id: any }) {
		const payload = {
			activityId: value.id,
			value,
			valueType: "irrigationActivity",
		};

		// Enqueue API-related mutations
		OfflineQueue.add("irrigation.updateActivity", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "irrigation.updateActivity" &&
				queueItem.payload.activityId === payload.activityId
			)
				return 1;
		});

		// Update local state
		return store.dispatch("updateIrrigationActivity", payload);
	},
	_updateActivity: function (payload: { value: any }) {
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("IrrigationActivity", payload.value)
						.then((response) => response.data)
						.then((data) => {
							resolve(data);
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	deleteActivity: function (value: { id: any }) {
		const payload = {
			activityId: value.id,
			value,
			valueType: "irrigationActivity",
		};

		// Enqueue API-related mutations
		OfflineQueue.add("irrigation.deleteActivity", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "irrigation.deleteActivity" &&
				queueItem.payload.activityId === payload.activityId
			)
				return 1;
		});

		// Update local state
		return store.dispatch("deleteIrrigationActivity", payload);
	},
	_deleteActivity: function (payload: { value: any }) {
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.delete("IrrigationActivity", { data: payload.value })
						.then((response) => response.data)
						.then((data) => {
							resolve(data);
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};
export default api;
