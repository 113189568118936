export var DuplicateResult;
(function (DuplicateResult) {
    /**
     * Add this mutation to the queue, and keep the other one
     */
    DuplicateResult[DuplicateResult["KeepBoth"] = 0] = "KeepBoth";
    /**
     * Add this mutation to the queue, and delete the old one
     */
    DuplicateResult[DuplicateResult["KeepIncoming"] = 1] = "KeepIncoming";
    /**
     * Discard this mutation (don't queue), keep the old one
     */
    DuplicateResult[DuplicateResult["DiscardIncoming"] = 2] = "DiscardIncoming";
    /**
     * Discard both mutations
     */
    DuplicateResult[DuplicateResult["DiscardBoth"] = 3] = "DiscardBoth";
})(DuplicateResult || (DuplicateResult = {}));
