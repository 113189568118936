import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "./token-validation";
import store from "@/store";
import { sendMessage } from "@/components/send-message";
import { TaskTypes } from "@/constants";
import seedgreenAPI from "@/lib/seedgreen-api";

const ENDPOINTS: { [key: number]: string } = {};
ENDPOINTS[TaskTypes.General] = "TaskRequest/CreateOrUpdate";
ENDPOINTS[TaskTypes.Thinning] = "TaskRequest/CreateOrUpdateThinField";
ENDPOINTS[TaskTypes.Weeding] = "TaskRequest/CreateOrUpdateWeedField";

const bundle = {
	/**
	 * Create
	 * @param {object} request
	 */
	createOrUpdate: function (request: any) {
		// Update local state if this task request already exists
		if (request.id) store.dispatch("createOrUpdateTaskRequests", request);

		// Enqueue API mutations
		OfflineQueue.add("taskRequest.createOrUpdate", request, function duplicateMerger(queueItem) {
			if (queueItem.endpoint === "taskRequest.createOrUpdate" && queueItem.id === request.id) return 1;
		});
	},
	_createOrUpdate: function (payload: any) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					// Call remote API
					seedgreenAPI
						.post(ENDPOINTS[payload[0].requestType], payload)
						.then((response) => response.data)
						.then((data) => {
							store.dispatch("createOrUpdateTaskRequests", data);

							resolve();
						})
						.catch(({ xhr, status, error }) => {
							sendMessage(`Failed to submit request: ${xhr.status} ${error}.  Please try again...`, 5000);
							console.debug(xhr);

							reject(error);
						});
				})
				.catch((e) => {
					sendMessage("Invalid credentials. Please try again...", 5000);
					reject(e);
				});
		});
	},
};

export default bundle;
